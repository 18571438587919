<template>
    <table width="100%">
      <thead>
        <tr>
          <td class="extra-table-holder">
            <table width="100%" class="extra-table">
              <thead>
                <tr>
                  <th>Bereifung</th>
                  <th>Bremsen</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                      <label for="tires_vr">
                      <input @change="updateReportData('tires_vr')" type="checkbox" id="tires_vr" value="tires_vr" name="selected" v-model="selected">VR</label>
                  </td>
                  <td>
                      <label for="breakes_vr">
                      <input @change="updateReportData('breakes_vr')" type="checkbox" id="breakes_vr" value="breakes_vr" name="selected" v-model="selected">VR</label>
                  </td>
                </tr>
                <tr>
                  <td>
                      <label for="tires_vl">
                      <input @change="updateReportData('tires_vl')" type="checkbox" id="tires_vl" value="tires_vl" name="selected" v-model="selected">VL</label>
                  </td>
                  <td>
                      <label for="breakes_vl">
                      <input @change="updateReportData('breakes_vl')" type="checkbox" id="breakes_vl" value="breakes_vl" name="selected" v-model="selected">VL</label>
                  </td>
                </tr>
                <tr>
                  <td>
                      <label for="tires_hr">
                      <input @change="updateReportData('tires_hr')" type="checkbox" id="tires_hr" value="tires_hr" name="selected" v-model="selected">HR</label>
                  </td>
                  <td>
                      <label for="breakes_hr">
                      <input @change="updateReportData('breakes_hr')" type="checkbox" id="breakes_hr" value="breakes_hr" name="selected" v-model="selected">HR</label>
                  </td>
                </tr>
                <tr>
                  <td>
                      <label for="tires_hl">
                      <input @change="updateReportData('tires_hl')" type="checkbox" id="tires_hl" value="tires_hl" name="selected" v-model="selected">HL</label>
                  </td>
                  <td>
                      <label for="breakes_hl">
                      <input @change="updateReportData('breakes_hl')" type="checkbox" id="breakes_hl" value="breakes_hl" name="selected" v-model="selected">HL</label>
                  </td>
                </tr>
                <tr>
                  <td>
                      <label for="tires_res">
                      <input @change="updateReportData('tires_res')" type="checkbox" id="tires_res" value="tires_res" name="selected" v-model="selected">Res.</label>
                  </td>
                  <td>
                      <label for="breakes_hbr">
                      <input @change="updateReportData('breakes_hbr')" type="checkbox" id="breakes_hbr" value="breakes_hbr" name="selected" v-model="selected">HB r</label>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                      <label for="breakes_hbl">
                      <input @change="updateReportData('breakes_hbl')" type="checkbox" id="breakes_hbl" value="breakes_hbl" name="selected" v-model="selected">HB l</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td class="rotated-header"><span>Lackstift</span></td>
          <td class="rotated-header"><span>Steinschlagsch.</span></td>
          <td class="rotated-header"><span>Beulen</span></td>
          <td class="rotated-header"><span>Kantenrost</span></td>
          <td class="rotated-header"><span>Unterrostungen</span></td>
          <td class="rotated-header"><span>Durchrostungen</span></td>
          <td class="rotated-header"><span>Lackieren</span></td>
          <td class="rotated-header"><span>DentWizard</span></td>
          <td class="rotated-header"><span>Polieren</span></td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in rows" :key="index">
          <td>{{ row.key }}</td>

          <td class="text-center" v-for="(column, columnIndex) in columns" :key="columnIndex">
            <input type="checkbox"
              class="align-center justify-center"
              hide-details
              name="selected"
              :value="row.value + '_' + column.value"
              v-model="selected"
              @change="updateReportData(row.value + '_' + column.value)"
            />
          </td>
        </tr>
      </tbody>
    </table>
</template>

<script>

import axios from 'axios'
export default {
  name: 'CostMatrix',
  data() {
    return {
    }
  },
  props: {
    chassisNumber: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    updateReportData(type) {
      axios
        .post(`api/workshop/${this.chassisNumber}/edit/matrix/${type}`)
        .then(response => {
          if (response.data.status === 'Success') {
            console.log(response.data)
          } else {
            this.$store.commit('setError', { error: true, errorMessage: 'Fehler beim Speichern der Daten' })
          }
        }).catch(() => {
          this.$store.commit('setError', { error: true, errorMessage: 'Fehler beim Speichern der Daten' })
        })
    }
  },
  computed: {
    rows() { return this.$store.state.matrixRowItems },
    columns() { return this.$store.state.matrixColumnItems },
    selected: {
      get() {
        return this.$store.state.matrixData
      },
      set(value) {
        this.$store.commit('setMatrixData', value)
      }
    }
  }
}
</script>

<style lang="scss">
  td,
  th{
    border: thin solid rgba(0,0,0,.12);
    border-collapse: collapse;
    border-spacing:0;

    &.text-center{
      height:50px;
      width:50px;
      text-align: center;
      vertical-align: middle;
    }
  }

  .rotated-header{
    vertical-align: bottom;
    padding-left: 65px;

    span{
       writing-mode: vertical-lr;
      -ms-writing-mode: tb-rl;
      transform: rotate(200deg);
    }
  }

  input[type=checkbox]
  {
    transform: scale(1.5);
    padding: 10px;
  }

  .extra-table{
    td{
      padding: 10px 20px;
    }

    input{
      margin-right:20px;
    }
  }

  .extra-table-holder{
    width: 30%
  }

  @media print {

    .extra-table-holder{
      width: 37%;
    }

    .rotated-header{
      padding-left: 13px;
      span{
        transform: rotate(180deg);
      }
    }
  }
</style>
