<template>
  <v-dialog
    v-model="show"
    fullscreen
    transition="dialog-bottom-transition"
  >

    <v-card>
      <v-toolbar
      >
        <v-btn
          icon
          @click="toggleParking"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Stellplatz</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-container>
        <v-row class="flex-child">

          <v-col cols="2">
              <v-sheet
                class="d-flex"
                :color="getColor('Berg')"
                @click.stop="setParking('Berg')"
                height="300"
              >
                <p class="d-flex justify-center align-end">
                  Berg
                </p>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" offset="2">
              <v-sheet
                class="d-flex"
                :color="getColor('Links')"
                @click.stop="setParking('Links')"
                height="50"
              >
                <p class="d-flex justify-center align-end">
                  Links
                </p>
            </v-sheet>
          </v-col>
          <v-col cols="5">
              <v-sheet
                class="d-flex"
                :color="getColor('Rechts')"
                @click.stop="setParking('Rechts')"
                height="50"
              >
                <p class="d-flex justify-center align-end">
                  Rechts
                </p>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" offset="2">
              <v-sheet
                class="d-flex"
                :color="getColor('Werkstatt')"
                @click.stop="setParking('Werkstatt')"
                height="150"
              >
                <p class="d-flex justify-center align-end">
                  Werkstatt
                </p>
            </v-sheet>
          </v-col>
          <v-col cols="5">
              <v-sheet
                class="d-flex"
                :color="getColor('Aufbereitung')"
                @click.stop="setParking('Aufbereitung')"
                height="150"
              >
                <p class="d-flex justify-center align-end">
                  Aufbereitung
                </p>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8" offset="2">
              <v-sheet
                class="d-flex"
                :color="getColor('Reihe Vorne')"
                @click.stop="setParking('Reihe Vorne')"
                height="50"
              >
                <p class="d-flex justify-center align-end">
                  Reihe Vorne
                </p>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8" offset="2">
              <v-sheet
                class="d-flex"
                :color="getColor('Autoplatz')"
                @click.stop="setParking('Autoplatz')"
                height="150"
              >
                <p class="d-flex justify-center align-end">
                  Autoplatz
                </p>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          Position: {{ location.coords.latitude }}, {{ location.coords.longitude}}
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: 'Parking',
  props: {
    value: Boolean,
    chassisNumber: String,
    parking: String
  },
  data() {
    return {
      location: null,
      gettingLocation: false,
      errorStr: null
    }
  },
  methods: {
    toggleParking() {
      this.$store.commit('toggleParking')
    },
    setParking(type) {
      this.$emit('setParking', { type })
    },
    getColor(type) {
      if (type === this.parking) {
        return 'green lighten-3'
      } else {
        return 'grey lighten-3'
      }
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  created() {
    // do we support geolocation
    if (!('geolocation' in navigator)) {
      this.errorStr = 'Geolocation is not available.'
      return
    }

    this.gettingLocation = true
    // get position
    navigator.geolocation.getCurrentPosition(pos => {
      this.gettingLocation = false
      this.location = pos
    }, err => {
      this.gettingLocation = false
      this.errorStr = err.message
    })
  }
}
</script>
<style lang="scss" scoped>
p{
  width:100%;
  font-weight: bold;
}
</styles>
