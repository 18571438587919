<template>
    <v-form id="document">
        <v-container>
            <v-row>
                <v-col cols="6">
                    <v-text-field
                        label="Eigentümer"
                        tabindex="1"
                        v-model="reportData.ownerName"
                      @blur="updateReportData('ownerName')"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="6">
                    <v-text-field
                        label=""
                        tabindex="2"
                        v-model="reportData.ownerStreet"
                      @blur="updateReportData('ownerStreet')"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="3">

                    <v-text-field
                        type="number"
                        maxlength="5"
                        tabindex="3"
                        v-model="reportData.ownerZip"
                      @blur="updateReportData('ownerZip')"
                    >
                    </v-text-field>
                </v-col>

                <v-col cols="3">
                    <v-text-field
                        label=""
                        tabindex="4"
                        v-model="reportData.ownerCity"
                      @blur="updateReportData('ownerCity')"
                    >
                    </v-text-field>
                </v-col>

                <v-col cols="2">
                    <v-text-field
                        label="Datum"
                        tabindex="5"
                        v-model="reportData.date"
                        type="date"
                      @blur="updateReportData('date')"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-text-field
                    label="Hersteller/Modell"
                    tabindex="8"
                    v-model="reportData.manufacturer"
                      @blur="updateReportData('manufacturer')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                    label="KW/PS"
                    tabindex="9"
                    v-model="reportData.capacity"
                    @blur="updateReportData('capacity')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                    label="Fahrgestellnummer"
                    tabindex="10"
                    v-model="chassisNumber"
                    :readonly="true"
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                    label="Kennzeichen"
                    tabindex="11"
                    v-model="reportData.licensePlate"
                      @blur="updateReportData('licensePlate')"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-text-field
                    label="Anzahl der Türen"
                    tabindex="13"
                    type="number"
                    v-model="reportData.doors"
                      @blur="updateReportData('doors')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                    label="Erstzulassungsdatum"
                    tabindex="14"
                    v-mask="'##/##'"
                    v-model="reportData.initialRegistration"
                      @blur="updateReportData('initialRegistration')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                    label="Tachostand"
                    tabindex="15"
                    v-model="reportData.speedoReading"
                      @blur="updateReportData('speedoReading')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                    label="Nächster HU/AU Termin"
                    v-mask="'##/##'"
                    tabindex="16"
                    v-model="reportData.nextAppointment"
                      @blur="updateReportData('nextAppointment')"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-input
                  label="Serviceheft vorhanden?"
                  hide-details></v-input>
                <v-radio-group
                  v-model="reportData.serviceBooklet"
                  row
                  @change="updateReportData('serviceBooklet')"
                >
                  <v-radio
                    label="Ja"
                    :value="1"
                    tabindex="17"
                  ></v-radio>
                  <v-radio
                    label="Nein"
                    :value="0"
                    tabindex="18"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="4">
                <v-input
                  label="Bereifung"
                  hide-details></v-input>
                  <v-row align="center">
                  <v-col cols="3">
                    <v-checkbox
                      hide-details
                      label="Sommer"
                      :value="1"
                      v-model="reportData.tiresSummer"
                      @change="updateReportData('tiresSummer')"
                      tabindex="19"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="3">
                    <v-checkbox
                      hide-details
                      label="Winter"
                      :value="1"
                      v-model="reportData.tiresWinter"
                      @change="updateReportData('tiresWinter')"
                      tabindex="20"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="3">
                  <v-checkbox
                    hide-details
                    label="Allwetter"
                    :value="1"
                    v-model="reportData.tiresAllWeather"
                    @change="updateReportData('tiresAllWeather')"
                    tabindex="21"
                  ></v-checkbox>
                </v-col>
                </v-row>
              </v-col>
              <v-col cols="2">
                <v-input
                  label="Insp. fortl. vorhanden"
                  hide-details></v-input>
                <v-radio-group
                  v-model="reportData.inspection"
                  row
                  @change="updateReportData('inspection')"
                >
                  <v-radio
                    label="Ja"
                    :value="1"
                    tabindex="21"
                  ></v-radio>
                  <v-radio
                    label="Nein"
                    :value="0"
                    tabindex="22"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="3">
                <v-input
                  label="Optischer Zustand des Fahrzeugs"
                  hide-details></v-input>
                <v-radio-group
                  v-model="reportData.optic"
                  row
                  @change="updateReportData('optic')"
                >
                  <v-radio
                    label="Gut"
                    value="Gut"
                    tabindex="23"
                  ></v-radio>
                  <v-radio
                    label="Mittel"
                    value="Mittel"
                    tabindex="24"
                  ></v-radio>
                  <v-radio
                    label="Schlecht"
                    value="Schlecht"
                    tabindex="25"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Zubehör"
                  v-model="reportData.accessories"
                      @blur="updateReportData('accessories')"
                    tabindex="26"
                >

                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-input
                  label="Vorschäden"
                  hide-details></v-input>

                <v-radio-group
                  v-model="reportData.damaged"
                  row
                  @change="updateReportData('damaged')"
                >
                  <v-radio
                    label="Ja"
                    :value="1"
                    tabindex="27"
                  ></v-radio>
                  <v-radio
                    label="Nein"
                    :value="0"
                    tabindex="28"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row v-if="reportData.damaged == '1'">
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Welche"
                  v-model="reportData.damages"
                  @blur="updateReportData('damages')"
                    tabindex="29"
                >

                </v-textarea>
              </v-col>
            </v-row>
            <div class="page-break"></div>
            <v-row>
              <v-col cols="12">
                <CostMatrix :chassisNumber="chassisNumber"></CostMatrix>
              </v-col>
            </v-row>
            <div class="page-break"></div>
            <v-row>
              <v-col cols="12">
                <v-row>

                    <v-col cols="8"></v-col><v-col cols="4"><h3>Kundenpreise BRUTTO</h3></v-col>
                    <v-col cols="8">
                      <v-textarea
                        outlined
                        hide-details
                        auto-grow
                        rows="1"
                          label="Karosserie/Lack"
                          v-model="reportData.carBodyNote"
                      @blur="updateReportData('carBodyNote')"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        hide-details
                          suffix="€"
                          type="number"
                          v-model="reportData.carBodyCosts"
                      @blur="updateReportData('carBodyCosts')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="8">
                      <v-textarea
                        outlined
                        hide-details
                        auto-grow
                        rows="1"
                          label="Motor"
                          v-model="reportData.engineNote"
                      @blur="updateReportData('engineNote')"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        hide-details
                          suffix="€"
                          type="number"
                          v-model="reportData.engineCosts"
                      @blur="updateReportData('engineCosts')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="8">
                      <v-textarea
                        outlined
                        hide-details
                        auto-grow
                        rows="1"
                          label="Getriebe, Differential"
                          v-model="reportData.gearboxNote"
                      @blur="updateReportData('gearboxNote')"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        hide-details
                          suffix="€"
                          type="number"
                          v-model="reportData.gearboxCosts"
                      @blur="updateReportData('gearboxCosts')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="8">
                      <v-textarea
                        outlined
                        hide-details
                        auto-grow
                        rows="1"
                          label="Kupplung, Schaltung"
                          v-model="reportData.couplingNote"
                      @blur="updateReportData('couplingNote')"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        hide-details
                          suffix="€"
                          type="number"
                          v-model="reportData.couplingCosts"
                      @blur="updateReportData('couplingCosts')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="8">
                      <v-textarea
                        outlined
                        hide-details
                        auto-grow
                        rows="1"
                          label="Vorderachse, Stoßdämpfer, Federung"
                          v-model="reportData.frontAxleNote"
                      @blur="updateReportData('frontAxleNote')"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        hide-details
                          suffix="€"
                          type="number"
                          v-model="reportData.frontAxleCosts"
                      @blur="updateReportData('frontAxleCosts')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="8">
                      <v-textarea
                        outlined
                        hide-details
                        auto-grow
                        rows="1"
                          label="Radlager"
                          v-model="reportData.wheelBearingNote"
                      @blur="updateReportData('wheelBearingNote')"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        hide-details
                          suffix="€"
                          type="number"
                          v-model="reportData.wheelBearingCosts"
                      @blur="updateReportData('wheelBearingCosts')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="8">
                      <v-textarea
                        outlined
                        hide-details
                        auto-grow
                        rows="1"
                          label="Lenkung, Gelenke"
                          v-model="reportData.steeringNote"
                      @blur="updateReportData('steeringNote')"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        hide-details
                          suffix="€"
                          type="number"
                          v-model="reportData.steeringCosts"
                      @blur="updateReportData('steeringCosts')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="8">
                      <v-textarea
                        outlined
                        hide-details
                        auto-grow
                        rows="1"
                          label="Hinterachse, Stoßdämpfer, Federung"
                          v-model="reportData.rearAxleNote"
                      @blur="updateReportData('rearAxleNote')"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        hide-details
                          suffix="€"
                          type="number"
                          v-model="reportData.rearAxleCosts"
                      @blur="updateReportData('rearAxleCosts')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="8">
                      <v-textarea
                        outlined
                        hide-details
                        auto-grow
                        rows="1"
                          label="Bremsen"
                          v-model="reportData.brakesNote"
                      @blur="updateReportData('brakesNote')"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        hide-details
                          suffix="€"
                          type="number"
                          v-model="reportData.brakesCosts"
                      @blur="updateReportData('brakesCosts')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="8">
                      <v-textarea
                        outlined
                        hide-details
                        auto-grow
                        rows="1"
                          label="Auspuffanlage"
                          v-model="reportData.exhaustNote"
                      @blur="updateReportData('exhaustNote')"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        hide-details
                          suffix="€"
                          type="number"
                          v-model="reportData.exhaustCosts"
                      @blur="updateReportData('exhaustCosts')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="8">
                      <v-textarea
                        outlined
                        hide-details
                        auto-grow
                        rows="1"
                          label="elektrische Anlagen, Batterie"
                          v-model="reportData.batteryNote"
                      @blur="updateReportData('batteryNote')"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        hide-details
                          suffix="€"
                          type="number"
                          v-model="reportData.batteryCosts"
                      @blur="updateReportData('batteryCosts')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="8">
                      <v-textarea
                        outlined
                        hide-details
                        auto-grow
                        rows="1"
                          label="Reifen"
                          v-model="reportData.tiresNote"
                      @blur="updateReportData('tiresNote')"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        hide-details
                          suffix="€"
                          type="number"
                          v-model="reportData.tiresCosts"
                      @blur="updateReportData('tiresCosts')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="8">
                      <v-textarea
                        outlined
                        hide-details
                        auto-grow
                        rows="1"
                          label="Polsterung, Wageninneres"
                          v-model="reportData.polsteryNote"
                      @blur="updateReportData('polsteryNote')"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        hide-details
                          suffix="€"
                          type="number"
                          v-model="reportData.polsteryCosts"
                      @blur="updateReportData('polsteryCosts')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="8">
                      <v-textarea
                        outlined
                        hide-details
                        auto-grow
                        rows="1"
                          label="optische Aufbereitung"
                          v-model="reportData.opticNote"
                      @blur="updateReportData('opticNote')"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        hide-details
                          suffix="€"
                          type="number"
                          v-model="reportData.opticCosts"
                      @blur="updateReportData('opticCosts')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="8">
                      <v-textarea
                        outlined
                        hide-details
                        auto-grow
                        rows="1"
                          label="Inspektion"
                          v-model="reportData.inspectionNote"
                      @blur="updateReportData('inspectionNote')"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        hide-details
                          suffix="€"
                          type="number"
                          v-model="reportData.inspectionCosts"
                          @blur="updateReportData('inspectionCosts')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="8">
                      <v-textarea
                        outlined
                        hide-details
                        auto-grow
                        rows="1"
                          label="Diverses"
                          v-model="reportData.diverseNote"
                          @blur="updateReportData('diverseNote')"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        hide-details
                          suffix="€"
                          type="number"
                          v-model="reportData.diverseCosts"
                      @blur="updateReportData('diverseCosts')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="8">
                      <h3 class="text-right">Summe</h3>
                    </v-col>
                    <v-col cols="4">
                      <h3>{{ completeCosts }}</h3>
                    </v-col>

                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      outlined
                        label="Notizen"
                        v-model="reportData.notes"
                      @blur="updateReportData('notes')"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" v-for="(image, index) in images" :key="index">
                <v-card
                  tile>
                  <v-img :src="image.path"></v-img>
                  <v-btn
                    fab
                    absolute
                    top
                    right
                    color="red darken-4 white--text"
                    v-on:click.stop="removeImage(image, index)">
                    <v-icon>
                      mdi-delete-forever
                    </v-icon>
                  </v-btn>
              </v-card>
              </v-col>
            </v-row>
      </v-container>
      <Camera v-if="showCamera"
              v-on:photoAccepted="addImage"
              v-on:photoRemoved="removeFromCamera"
              v-model="showCamera"
              :chassisNumber="chassisNumber"
              :images="images">
      </Camera>
      <Parking v-if="showParking"
               v-model="showParking"
               v-on:setParking="setParking"
               :chassisNumber="chassisNumber"
               :parking="reportData.parking">
      </Parking>
    </v-form>
</template>

<script>
import CostMatrix from './CostMatrix.vue'
import Camera from './Camera.vue'
import Parking from './Parking.vue'
import { format } from 'date-fns'
import axios from 'axios'

export default {
  name: 'ReportForm',
  components: {
    CostMatrix,
    Camera,
    Parking
  },
  props: {
    chassisNumber: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
    }
  },
  methods: {
    addImage(image) {
      this.images.push(image.image)
    },
    updateReportData(type) {
      var value = this.reportData[type]
      if (((type === 'tiresSummer' || type === 'tiresWinter' || type === 'tiresAllWeather') && value === null) || value === 0) {
        value = '0'
      }

      if (value && value !== null && value !== '') {
        if (type === 'nextAppointment' || type === 'initialRegistration') {
          value = value.replace('/', '')
        }

        axios
          .post(`api/workshop/${this.chassisNumber}/edit/${type}/${value}`)
          .then(response => {
            if (response.data.status === 'Success') {
              console.log(response.data)
            } else {
              this.$store.commit('setError', { error: true, errorMessage: 'Fehler beim Speichern der Daten' })
            }
          }).catch(() => {
            this.$store.commit('setError', { error: true, errorMessage: 'Fehler beim Speichern der Daten' })
          })
      }
    },
    setParking(type) {
      this.reportData.parking = type.type
      this.updateReportData('parking')
    },
    removeImage(image, key) {
      axios
        .post(`api/workshop/image/${image.id}/remove`)
        .then(response => {
          if (response.data.status === 'Success') {
            this.reportData.images.splice(key, 1)
          } else {
            this.$store.commit('setError', { error: true, errorMessage: 'Fehler beim Löschen des Bildes' })
          }
        }).catch(() => {
          this.$store.commit('setError', { error: true, errorMessage: 'Fehler beim Löschen des Bildes' })
        })
    },
    removeFromCamera(data) {
      this.removeImage(data.image, data.key)
    }
  },
  computed: {
    reportData() { return this.$store.state.reportData },
    images() {
      var images = []
      this.reportData.images.forEach(function (image) {
        images.push(image)
      })
      return images
    },
    showCamera() { return this.$store.state.showCamera },
    showParking() { return this.$store.state.showParking },
    date() {
      if (this.reportData.date) {
        return this.reportData.date
      } else {
        return format(Date.now(), 'dd.MM.Y')
      }
    },
    completeCosts() {
      var total = 0
      var costArray = ['carBodyCosts', 'engineCosts', 'gearboxCosts', 'couplingCosts', 'frontAxleCosts', 'wheelBearingCosts', 'steeringCosts', 'rearAxleCosts', 'brakesCosts', 'exhaustCosts', 'batteryCosts', 'tiresCosts', 'polsteryCosts', 'opticCosts', 'inspectionCosts', 'diverseCosts']

      costArray.forEach(key => {
        if (this.reportData[key]) {
          total += parseInt(this.reportData[key])
        }
      })

      return total + '€'
    }
  }
}
</script>

<style lang="scss" scoped>
  .page-break {
    break-before: page !important
  }
</style>
