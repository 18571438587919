<template>
        <v-dialog
          v-model="show"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-alert dismissible type="error" v-if="error">
            Es ist ein Fehler aufgetreten
          </v-alert>
          <v-card>
            <v-toolbar
            >
              <v-btn
                icon
                @click="toggleCamera"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Kamera</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>

            <v-container fluid>
                <v-row>
                  <v-col cols="6" offset="3">
                    <div class="camera--container"
                          v-on:click="takePicture">
                        <!-- Camera sensor -->
                        <canvas id="camera--sensor"></canvas>
                        <!-- Camera view -->
                        <video id="camera--view" autoplay playsinline></video>
                        <!-- Camera output -->
                        <img src="//:0" alt="" id="camera--output">
                    </div>
                    <div class="form-group">
                        <v-btn
                            color="primary"
                            id="save--image"
                            v-show="photoTaken"
                            v-on:click="setPhotoAccepted">
                            Foto übernehmen
                        </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" v-for="(image, index) in images" :key="index">
                    <v-card
                        tile>
                        <v-img :src="image.path"></v-img>
                        <v-btn
                          fab
                          absolute
                          top
                          right
                          color="red darken-4 white--text"
                          v-on:click.stop="removeImage(image, index)">
                          <v-icon>
                            mdi-delete-forever
                          </v-icon>
                        </v-btn>
                    </v-card>
                  </v-col>
                </v-row>
            </v-container>
          </v-card>
        </v-dialog>
</template>
<script>
import axios from 'axios'
export default {
  name: 'Camera',
  props: {
    image: String,
    value: Boolean,
    chassisNumber: String,
    images: Array
  },
  data() {
    return {
      photoTaken: false,
      currentImage: this.$props.image,
      error: false
    }
  },
  watch: {
    image: function (newVal) {
      this.currentImage = newVal
    }
  },
  created() {
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: 'environment' }, audio: false })
      .then(function (stream) {
        // track = stream.getTracks()[0];
        document.querySelector('#camera--view').srcObject = stream
      })
      .catch(function (error) {
        console.error('Oops. Something is broken.', error)
      })
  },
  methods: {
    takePicture() {
      const cameraView = document.querySelector('#camera--view')
      const cameraOutput = document.querySelector('#camera--output')
      const cameraSensor = document.querySelector('#camera--sensor')

      cameraSensor.width = cameraView.videoWidth
      cameraSensor.height = cameraView.videoHeight
      cameraSensor.getContext('2d').drawImage(cameraView, 0, 0)
      cameraOutput.src = cameraSensor.toDataURL('image/png')
      this.currentImage = cameraSensor.toDataURL('image/png')
      this.photoTaken = true
      cameraOutput.classList.add('taken')
    },
    setPhotoAccepted() {
      axios
        .post('/api/workshop/image/store', { chassisNumber: this.chassisNumber, image: this.currentImage })
        .then(response => {
          if (response.data.status === 'Success') {
            this.photoTaken = false
            document.querySelector('#camera--output').classList.remove('taken')
            this.$emit('photoAccepted', { image: response.data.data })
          } else {
            this.error = true
          }
        })
        .catch(() => {
          this.error = true
        })
    },
    toggleCamera() {
      this.$store.commit('toggleCamera')
    },
    removeImage(image, key) {
      this.$emit('photoRemoved', { image, key })
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.camera--container{
}

#camera,
#camera--view,
#camera--output {
    height: 32%;
    width: 100%;
    object-fit: cover;
}

#camera--output{
    height:0;
    width:0;

    &.taken {
        position: absolute;
        height: 100px !important;
        width: 100px !important;
        transition: all 0.5s ease-in;
        border: solid 3px white;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
        top: 10%;
        right: 5%;
        z-index: 2;
    }
}

#camera--sensor {
    width: 0;
}
</styles>
